<template>
    
  <div class="row justify-center">
    <h4>L'asso</h4>
  </div>

  <div class="row justify-center">
    <div class="col-xs-10 col-md-6">


      <div class="regular-text">
        Maintenant que vous avez parfaitement compris comment ça fonctionne (c’était facile non ?), vous pouvez vous reporter à notre (superbe) organigramme pour savoir qui fait quoi en pratique  : 
      </div>

      <div style="display: flex; justify-content: center;">
        <img src="../../../public/img/asso.png">
      </div>

    </div>
  </div>

  <div class="row justify-center" style="margin-top: 40px; margin-bottom: 30px;">
    <div class="col-xs-10 col-md-6" style="display: flex; justify-content: space-evenly;">

    <router-link to="/association/charte">
      <q-btn color="primary" label="◄&nbsp;&nbsp;&nbsp;La charte" />
    </router-link>

    <router-link to="/association/mangroves">
      <q-btn color="primary" label="Les mangroves&nbsp;&nbsp;&nbsp;►" />
    </router-link>

  </div>
</div>  

</template>  


<style scoped>

.list-element {
  margin-bottom: 10px;
}

.indent {
  text-indent: 50px;
}

</style>